import React from "react";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid2";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Color from "color"; // v3.2.1

const CardActionAreaActionArea = styled(CardActionArea)(() => ({
    borderRadius: 16,
    transition: "0.2s",
    "&:hover": {
        transform: "scale(1.1)",
    },
}));

const StyledCard = styled(Card)(({ color }) => ({
    minWidth: 256,
    borderRadius: 16,
    boxShadow: "none",
    "&:hover": {
        boxShadow: `0 6px 12px 0 ${Color(color).rotate(-12).darken(0.2).fade(0.5)}`,
    },
}));

const CardContentContent = styled(CardContent)(({ color }) => {
    return {
        backgroundColor: color,
        padding: "1rem 1.5rem 1.5rem",
    };
});

const TypographyTitle = styled(Typography)(() => ({
    fontFamily: "Keania One",
    fontSize: "2rem",
    color: "#fff",
    //textTransform: "uppercase",
}));

const TypographySubtitle = styled(Typography)(() => ({
    fontFamily: "Montserrat",
    color: "#fff",
    opacity: 0.87,
    marginTop: "2rem",
    fontWeight: 500,
    fontSize: 14,
}));

const CustomCard = ({
    color,
    image,
    title,
    subtitle,
}: {
    color: string;
    image: string;
    title: string;
    subtitle: string;
}) => (
    <CardActionAreaActionArea>
        <StyledCard color={color}>
            <CardMedia
                image={image}
                sx={{
                    width: "100%",
                    height: 0,
                    paddingBottom: "100%",
                    backgroundColor: "rgba(0,0,0,0.08)",
                }}
            />
            <CardContentContent color={color}>
                <TypographyTitle variant={"h2"}>{title}</TypographyTitle>
                <TypographySubtitle>{subtitle}</TypographySubtitle>
            </CardContentContent>
        </StyledCard>
    </CardActionAreaActionArea>
);

export default function Products(props) {
    const { classes } = props;
    return (
        <div id="Products" className="bg-gray-100" >
        <section class='center'>
            <div className="my-8 py-8 mx-8 px-8">
                <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">Our Products</h2>
                <div className='flex justify-center'>
                    <div className='w-24 border-b-4 border-blue-900'></div>
                </div>
                <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">Some of our products.</h2>
            </div>
            
            <Grid container spacing={5}  display="flex" container direction="row"  alignItems="center" justify="center" style={{ minHeight: '70vh' }}>
                <Grid>
                    <CustomCard
                        color={"#203f52"}
                        title={"AI Native"}
                        subtitle={"Chatgpt inside!"}
                        image={
                            "https://play-lh.googleusercontent.com/5UHDhw302NSSfvv7iSwgy8QXx9suD7opcH_WBcd4gR8G8y6nFohDlJ2BFJ9N8abpkg=w1052-h592-rw"
                        }
                    />
                </Grid>
                <Grid>
                    <CustomCard
                        color="#4d137f"
                        title={"Free call"}
                        subtitle={"Call to worldwide free!"}
                        image={
                            "https://play-lh.googleusercontent.com/gykofvhqLa682UspwcCWKDeGO5a2nlyyq1GGvPiLr9gWrmN5pgzQYIPFMUoq2m3Kc40=w1052-h592-rw"
                        }
                    />
                </Grid>
                <Grid>
                    <CustomCard
                        color="#ff9900"
                        title={"Mini game"}
                        subtitle={"play mini game free!"}
                        image={"https://play-lh.googleusercontent.com/oER-U0EtVhmmOdwoEHQusu6Zx51pxJVv4BRapjz2BZ-uvOoD60fq2tR3KeceqdwixTU=w1052-h592-rw"}
                    />
                </Grid>
                <Grid>
                    <CustomCard
                        color="#34241e"
                        title={"Emoj text"}
                        subtitle={"Text with emoj and multimedia?"}
                        image={
                            "https://play-lh.googleusercontent.com/SbxqCBzuhbJ2KiH6j0Sm5ib6v2N0ZQV1K7HVA0KFHIGjL3WXNEAbrwzRSOIXOs3h1NM=w1052-h592-rw"
                        }
                    />
                </Grid>
                <Grid>
                    <CustomCard
                        color="#34241e"
                        title={"8 way meeting"}
                        subtitle={"Support 8 way conference?"}
                        image={
                            "https://play-lh.googleusercontent.com/VFUlzMALsg4SyHRPo51cgCVANJQGAVqOY2ICk64wZqbZYNhURawvLMKNYoshaIFuDAo=w1052-h592-rw"
                        }
                    />
                </Grid>
            </Grid>
        </section>
        </div>
    );
}